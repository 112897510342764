import { ViewModelComponentProps } from '@/utils/typeUtilities'
import ConnectUtilityCardRoot, {
  Props as ComponentProps,
} from './ConnectUtilityCard'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function ConnectUtilityCard(props: Props) {
  const viewModel = useViewModel()
  return <ConnectUtilityCardRoot {...viewModel} {...props} />
}

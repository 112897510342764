import './styles.scss'
import { Card, CardProps, LoadingAnimation } from '@/components'
import { GridSupport } from '@/authenticated/components/GridSupport'
import formatClasses from '@/utils/classes/formatClasses'
import { BreakpointContext } from '@/context'
import { useContext } from 'react'
import UtilityProgramEnrollmentFlow from '../../utility-programs/UtilityProgramEnrollmentFlow/'
import useViewModel from './useViewModel'

export type ConnectionMode = 'program' | 'grid-support'

export type Props = CardProps &
  ReturnType<typeof useViewModel> & {
    onCloseClick?: () => void
    enrollError?: string | { detail: string }
    setEnrollError?: React.Dispatch<React.SetStateAction<string | undefined>>
  }

export default function ConnectUtilityCard({
  user,
  utilitySelection,
  connectionMode,
  onBack,
  onGridSupportSubmit,
  onSkipEnrollment,
  onSkipGridSupport,
  isLoading,
  className,
  ...props
}: Props) {
  const breakpoint = useContext(BreakpointContext)

  const classes = {
    gridSupportCard: formatClasses([
      'connect-utility-card-grid-support',
      className,
    ]),
  }

  if (isLoading) {
    return (
      <Card className={className} flat={breakpoint.smAndDown} {...props}>
        <div className="flex justify-center items-center h-40">
          <LoadingAnimation type="falling" />
        </div>
      </Card>
    )
  }

  return connectionMode === 'grid-support' ? (
    <Card className={classes.gridSupportCard} flat {...props}>
      <GridSupport
        utilitySelection={utilitySelection}
        user={user}
        handleSubmit={onGridSupportSubmit}
        handleClose={onSkipGridSupport}
        handleBack={onBack}
        allowSkip={true}
        handleSkip={onSkipGridSupport}
      />
    </Card>
  ) : (
    <UtilityProgramEnrollmentFlow
      onBack={onBack}
      onSkip={onSkipEnrollment}
      flat
      {...props}
    />
  )
}

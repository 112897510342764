import { useAppDispatch, useAppSelector, useUrlSearchParam } from '@/hooks'
import {
  selectUtilityProgramForUserUtility,
  utilityProgramCollection,
} from '@/reducers/utilityPrograms'
import { makeGridSupportSelection } from '@/actions/utilities'
import { getChargeSchedules } from '@/actions/schedule'
import { ConnectionMode } from './ConnectUtilityCard'
import * as Sentry from '@sentry/browser'
import { RequestStatus } from '@/request/types'
import { UtilitySearchResult } from '@/types/utility'
import { useNavigation } from '@/app/hooks'
import { useLocation } from 'react-router-dom'

const useFetchingUtilityProgram = (utilityId?: number) => {
  const { status, data } = utilityProgramCollection.useFetch({
    params: { utility_id: utilityId },
    require: utilityId !== null && utilityId !== undefined,
  })
  const program = data.find((u) => u.id === utilityId)
  const utilitySearch = { name: program?.name ?? '' } as UtilitySearchResult
  const utilityProgram = useAppSelector((state) =>
    selectUtilityProgramForUserUtility(state, utilitySearch),
  )
  const succeeded = status === RequestStatus.Succeeded

  return { utilityProgram, succeeded }
}

export default function useViewModel() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const utilityProgramId = searchParams.has('utility_program_id')
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      +searchParams.get('utility_program_id')!
    : null
  const navigation = useNavigation()
  const user = useAppSelector((state) => state.user.user)
  const utilitySelection = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )
  const { utilityProgram, succeeded } = useFetchingUtilityProgram(
    utilityProgramId ?? utilitySelection?.id ?? undefined,
  )
  const fromConnectUtilityFlow = Boolean(
    useUrlSearchParam('from_connect_utility'),
  )
  const flowParam = useUrlSearchParam('flow')
  const upeFlow = flowParam === 'upe'
  const gridSupportFlow = flowParam === 'grid'

  const connectionMode: ConnectionMode = upeFlow
    ? 'program'
    : gridSupportFlow
    ? 'grid-support'
    : utilityProgram
    ? 'program'
    : 'grid-support'

  const setGridSupportSelection = (support: boolean) => {
    const promise = dispatch(
      makeGridSupportSelection(
        {
          has_made_grid_support_selection: support,
        },
        dispatch,
      ),
    )
    if (promise) {
      // refresh charges, because they will all be updated to reflect the new grid support adoption
      promise.then(() => dispatch(getChargeSchedules()))
    } else {
      Sentry.captureMessage('makeGridSupportSelection returning null promise')
    }
  }

  const onBack = () => {
    navigation.goBack()
  }

  const onSkip = () => {
    navigation.push(`/connect-utility/plan?from_UPE=true`)
  }

  const onConnect = () => {
    if (fromConnectUtilityFlow) {
      navigation.push(`/connect-utility/plan?from_UPE=true`)
    } else {
      navigation.push('/devices')
    }
  }

  const onGridSupportSubmit = (support: boolean) => {
    setGridSupportSelection(support)
    if (support) {
      onConnect()
    }
  }

  const onSkipEnrollment = () => {
    onSkip()
    setGridSupportSelection(false)
  }

  const onSkipGridSupport = () => {
    onSkip()
  }

  return {
    user,
    utilitySelection,
    utilityProgram,
    connectionMode,
    onBack: !fromConnectUtilityFlow ? onBack : undefined,
    onGridSupportSubmit,
    setGridSupportSelection,
    onSkipEnrollment: fromConnectUtilityFlow ? onSkipEnrollment : undefined,
    onSkipGridSupport: fromConnectUtilityFlow ? onSkipGridSupport : undefined,
    isLoading: !succeeded,
    onConnect,
  }
}

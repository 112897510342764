import { NavigationPage } from '@/app/components'
import ConnectUtilityCard from '@/app/features/integrations/ConnectUtilityCard'

const UtilityProgramEnrollmentPage = () => {
  return (
    <NavigationPage id="utility-program-enrollment-page">
      <NavigationPage.SingleCardContent>
        <ConnectUtilityCard />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}

export default UtilityProgramEnrollmentPage
